import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Pvp from "./Pages/Pvp/Pvp";

import "./Styles/App.scss";
import "./Styles/Animation.scss";
import { useState } from "react";
import TelegramBot from "./Pages/TelegramBot/TelegramBot";
import RevenueSharing from "./Pages/RevenueSharing/RevenueSharing";

function App() {
  
  const [location, setLocation] = useState(null);
  
  return (
    <>
      <Navbar location={location}/>
      
      <Router>
        <Routes>
          <Route path="/" element={
            <Home setLocation={setLocation}/>
          }/>
          <Route path="/pvp" element={
            <Pvp setLocation={setLocation}/>
          }/>
          <Route path="/revenue-sharing" element={
            <RevenueSharing setLocation={setLocation}/>
          }/>
          <Route path="/telegrambot" element={
            <TelegramBot setLocation={setLocation}/>
          }/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
