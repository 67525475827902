import { useEffect, useRef } from "react";

import Style from "./TelegramBot.module.scss";
import CommunitySVG from "../../Components/CommunitySVG/CommunitySVG";

export default function TelegramBot({setLocation}){
    const textRef = useRef();

    const parallax = (e) =>{
        let _w = window.innerWidth/2;
        let _h = window.innerHeight/2;
        const speed = (50 - (e.clientX - _w))*0.01;
        const speed2 = (50 - (e.clientY - _h))*0.01;
        textRef.current.style.transform = `translateY(${-speed*speed2}px) rotate(-90deg)`;
    }

    useEffect(()=>{
        setLocation((window.location.pathname).replace("/",""));
        window.addEventListener("mousemove", (e)=>parallax(e))
    },[])

    return (
        <div className={Style.pvp}>
            <span ref={textRef} className={Style.horizontalText}>worldwardao.army</span>
            
            <div className={Style.content}>
                   
                <div className={Style.animatedTitle}>
                    <h1>Telegram Bot</h1>
                </div>
                <p>
                    Our Telegram Bot <b><a href="https://t.me/@WorldWarDaoBot" target="_blank">@WorldWarDaoBot</a></b> is one of the most important part of WorldWarDAO!
                    Using the bot enables our users to fully experience and <b>enjoy 100% the functionalities</b> of WorldWarDAO.
                </p>
                    
                <div className={Style.leftRightSection}>
                    <div className={Style.leftCol}>
                        <h3>Innovation</h3>
                        <p>
                            We firmly believe that a <b>significant challenge in Decentralized GameFi and Dapps</b>, in general, is the <b>limited communication with users</b> outside the Dapp.
                            <br/><br/>Thanks to <b><a href="https://t.me/@WorldWarDaoBot" target="_blank">@WorldWarDaoBot</a></b> coupled with our <b>innovative technologie of on-chain monitoring</b> we <b>solve this lack of communication</b>.<br/>
                        </p>
                    </div>
                    <div className={Style.rightCol}>
                        <h3>How does the Bot solve this problem?</h3>
                        <p>
                        We have the capability to deliver <b>real-time notifications</b> to users, even when they are not actively playing. <br/>This feature <b>enhances player awareness</b> of their in-game status.
                        <br/><br/>For instance, the bot can <b>alert you when your base is under attack</b> and provide the outcome of the battle. Additionally, it can be utilized to <b>check your current pending rewards</b> without requiring you to log in, <b>streamlining the user experience</b>.
                        </p>
                    </div>
                    <div className={Style.leftCol}>
                        <h3>Increasing retention rate</h3>
                        <p>
                            The bot is also a real <b>game changing tool</b> significantly <b>boosting the retention rate</b> of a Dapp or Game.<br/><br/>
                            Users often overlook the games or Dapps they've engaged with, sometimes for days, or even permanently.<br/><br/>
                            <b>The bot acts as a reminder</b>, encouraging users to <b>return regularly</b>, claim their rewards, or simply reconnect and utilize the product.<br/> 
                            <b>Retention rate</b> stands out as a <b>crucial key</b> to the <b>long-term success</b> of any project.
                            <br/><b>If you're a developer working on a Dapp or GameFi project, we'd be delighted to share this tool with you!
                            <br/><br/>Please don't hesitate to reach out to us at <a href="mailto:worldwardao@gmail.com" target="_blank">worldwardao@gmail.com</a>.</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}