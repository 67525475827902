import Style from "./Pvp.module.scss";

import { useEffect, useRef } from "react";

import Video from "../../Assets/FightNotify.mp4";

export default function Pvp({setLocation}){
    const textRef = useRef();

    const parallax = (e) =>{
        let _w = window.innerWidth/2;
        let _h = window.innerHeight/2;
        const speed = (50 - (e.clientX - _w))*0.01;
        const speed2 = (50 - (e.clientY - _h))*0.01;
        textRef.current.style.transform = `translateY(${-speed*speed2}px) rotate(-90deg)`;
    }

    useEffect(()=>{
        setLocation((window.location.pathname).replace("/",""));
        window.addEventListener("mousemove", (e)=>parallax(e))
    },[])

    return (
        <div className={Style.pvp}>
            <span ref={textRef} className={Style.horizontalText}>worldwardao.army</span>
            
            <div className={Style.content}>
                <div className={Style.titleRow}>
                    <div className={Style.col}>
                        <div className={Style.animatedTitle}>
                            <h1>Player VS Player</h1>
                        </div>
                        <p>Player vs. Player (PvP) in WorldWarDAO is one of the most crucial aspects of the game.<br/>Once you commence building your base, any adversary can launch an attack, potentially seizing a portion of your GUNZ rewards & locked ETH, and temporarily slowing down your GUNZ production for a few hours.
                        <br/><br/>You get notified by our <b>Telegram Bot</b> everytime an attack occurs!
                        </p>
                    </div>
                    <video src={Video} autoPlay loop muted={true} controls={false}></video>
                </div>
                

                <div className={Style.leftRightSection}>
                    <div className={Style.leftCol}>
                        <h3>How does it works?</h3>
                        <p>PvP utilizes a Chainlink VRF Oracle along with a proprietary formula that factors in your Army Power and that of your opponent to calculate the probability of winning the fight.</p>
                    </div>
                    <div className={Style.rightCol}>
                        <h3>How many attacks can I do?</h3>
                        <p>You have 1 free attack every 12h. If you want to keep fighting you can buy each attack for a small ETH fee!</p>
                    </div>

                    <div className={Style.leftCol}>
                        <h3>What is the Shield?</h3>
                        <p>The Shield offers temporary protection from PvP encounters. It can be obtained after successfully defending against an attack or purchased using ETH. <br/>This advantage is pivotal in the game, ensuring the continuity of your production and preventing adversaries from stealing your GUNZ and locked ETH.</p>
                    </div>

                    <div className={Style.leftCol}>
                        <h2>I am launching an attack</h2>
                        <h3>I won the fight</h3>
                        <p>When you win a fight you steal the pending rewards of your oponent and a part of his locked ETH. In addition you also steal the equivalent of 12h of his Military Base production.<br/>It provides a significant advantage, particularly compared to someone who doesn't engage in daily battles, enabling you to scale your Military Base.</p>
                    </div>

                    <div className={Style.rightCol}>
                        <h3>I lost the fight</h3>
                        <p>No gains this time! Your free attack has been used, yielding no rewards. If you wish to launch another attack, a small ETH fee will be required.</p>
                    </div>

                    <div className={Style.leftCol}>
                        <h2 className={Style.red}>I have been attacked</h2>
                        <h3>I lost the fight</h3>
                        <p>When you lose a fight your unclaimed Military Base GUNZ production is stolen! (That’s why it’s important to play as often as possible and come to claim your rewards).<br/>You also lose a part of your locked ETH and your production is shutted down for a few hours.<br/>This places you at a significant disadvantage compared to other players and considerably hampers your in-game progression.</p>
                    </div>

                    <div className={Style.rightCol}>
                        <h3>I won the fight</h3>
                        <p>If your Military Base is targeted in an attack but the assailant is unsuccessful, you'll receive a Shield.<br/>This protective measure shields your base from PvP encounters for a specified duration, providing a temporary safeguard against further attacks.</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}