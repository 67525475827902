import HomeStyle from "./Home.module.scss";

import { useEffect, useRef } from "react";

import CommunitySVG from "../../Components/CommunitySVG/CommunitySVG";

import AppOnMac from "../../Assets/AppOnMac.png";

import AirdropIcon from "../../Assets/AirdropIcon.png";

import SocialRow from "../../Components/SocialRow/SocialRow";

import Trailer from "../../Assets/Trailer.mp4";
import BoostAirdrop from "../../Assets/BoostAirdrop.mp4";

export default function Home({setLocation}){
    const textRef = useRef();

    const parallax = (e) =>{
        let _w = window.innerWidth/2;
        let _h = window.innerHeight/2;
        const speed = (50 - (e.clientX - _w))*0.01;
        const speed2 = (50 - (e.clientY - _h))*0.01;
        textRef.current.style.transform = `translateY(${-speed*speed2}px) rotate(-90deg)`;
    }

    useEffect(()=>{
        setLocation((window.location.pathname).replace("/",""));
        window.addEventListener("mousemove", (e)=>parallax(e))
    },[])

    return (
        <div className={HomeStyle.home}>
            <span ref={textRef} className={HomeStyle.horizontalText}>worldwardao.army</span>
            
            <div className={HomeStyle.firstSection}>
                <div className={HomeStyle.col}>
                    <div className={HomeStyle.titleSection}>
                        <h2>Discover the new generation of GameFi</h2>
                        <div className={HomeStyle.animatedTitle}>
                            <h1>EVERY BATTLE</h1>
                        </div>
                        <div className={`${HomeStyle.animatedTitle}`}>
                            <h1 className={`${HomeStyle.delayed}`}>IS A STEP TO WEALTH.</h1>
                        </div>
                    </div>
                    
                    <div className={HomeStyle.btnRow}>
                        <button className={`${HomeStyle.button} ${HomeStyle.play}`} onClick={()=>window.open('https://beta.worldwardao.army/')}>Start Playing</button>
                        <button className={`${HomeStyle.button} ${HomeStyle.discover}`} onClick={()=>{
                            document.getElementById("gameplaySection")?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }}>Discover</button>
                    </div>
                    
                    <SocialRow/>
                </div>
                <div className={HomeStyle.col}>
                    <video loop controls={false} autoPlay muted src={Trailer} className={HomeStyle.trailer}></video>
                </div>

                <div className={HomeStyle.overlay}></div>
                <div className={HomeStyle.roundGradient}></div>
                <div className={HomeStyle.gradient}></div>
            </div>

            <div className={HomeStyle.section}>
                <div className={HomeStyle.clip}>
                    <svg viewBox="0 0 2065 1650" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="clipMask">
                            <circle cx="727" cy="409" r="95" fill="#D9D9D9"/>
                            <circle cx="900" cy="588" r="49" fill="#D9D9D9"/>
                            <ellipse cx="1088" cy="264" rx="94" ry="93" fill="#D9D9D9"/>
                            <path d="M1097.8 589.571C534.771 744.157 1379.78 1321.32 267 1650H2065V19.6086C1903.86 -17.8285 1740.86 -9.3531 1618.04 113.364C1315.71 415.422 1504.67 477.861 1097.8 589.571Z" fill="#D9D9D9"/>
                        </mask>
                        <image xlinkHref={"/assets/clipBg.png"} mask="url(#clipMask)"></image>
                    </svg>
                </div>

                <div className={HomeStyle.sectionContent} id="gameplaySection">
                    <h1>What is WorldWarDAO</h1>
                    <h2>An easy and funny gameplay</h2>
                    <p>
                        <b>WorldWarDao</b> is a new type of <b>on-chain game</b>, allowing participant to play in a fictive <b>DeFi</b> powered world where <b>3 nations fight</b> against each other.
                        <br/><br/>The game is powered by <b>$WWD</b> used for revenue sharing and <b>$GUNZ</b> the in-game token.
                        <br/><br/>Players have to build their <b>Military Base</b> using (<b>$GUNZ</b>) and fight each other through the <b style={{color:"#FFCB45"}}>Player VS Player</b> mode earning <b>in-game boosts</b> & <b>rewards</b> in addition of <b>locked ETH revenues</b>.
                    </p>
                </div>
            </div>

            <div className={HomeStyle.section}>
                <div className={`${HomeStyle.sectionContent} ${HomeStyle.airdropSection}`} id="betaToAirdrop">
                    <div className={HomeStyle.row}>
                        <div className={HomeStyle.col}>
                            <h1>Beta to Airdrop</h1>
                            <p><b>WorldWarDAO Game</b> is <b>already available in Beta</b> on <b style={{color:"#44A5FF"}}>Arbitrum Goerli</b>. 
                            <br/>You can play on <b>mobile</b> via our <b>Progressive Web App</b> (PWA) or on <b>Desktop</b> at <b style={{color:"#FFCB45"}}>beta.worldwardao.army</b>.
                            <br /><br />
                            Beta participants will be eligible for an <b>Airdrop</b> of our token <b>$WWD</b>. To <b>join the beta</b>, you need to use an <b>invitation code</b>.
                            <br /><br />
                            <b>Current beta members</b> can <b>share invitation codes</b> within the game. More invitations you send, the larger your <b>Airdrop</b> allocation will grow.
                            </p>
                            <br />
                            <a href="https://docs.worldwardao.army/tokenomics/beta-to-airdrop">Check our documentation for more details</a>
                        </div>
                        <div className={HomeStyle.col}>
                            <video src={BoostAirdrop} autoPlay muted controls={false} loop className={HomeStyle.video}></video>
                            {/* <img className={HomeStyle.airdropIcon} src={AirdropIcon} alt="AirdropIcon" /> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className={HomeStyle.section}>
                <div className={`${HomeStyle.sectionContent}`}>
                    <div className={HomeStyle.row}>
                        <div className={HomeStyle.col}>
                            <h1>More than a game</h1>
                            <h2>What’s behind WorldWarDAO</h2>
                            <p>
                            <b>WorldWarDao</b> aims to be more than a game. It's a step forward in the GameFi sector. We want to build a <b>strong community</b> of <b>engaged players</b> and <b>GameFi lovers</b> that will support the GameFi development on <b style={{color:"#44A5FF"}}>Arbitrum</b>.
                            <br/><br/>We are using unique <b>Game designs</b> & <b>Revenue Sharing</b> mechanism that aims to makes our game <b>last for years</b> and still <b>worth to play</b>, unlike our predecessor.
                            </p>
                            {/* <button className={`${HomeStyle.button} ${HomeStyle.buttonRed}`} onClick={()=>window.open("https://docs.worldwardao.army/")}>Discover the leaders</button> */}
                        </div>

                        {/* <ImageSlider/> */}
                    </div>
                </div>
            </div>

            <div className={HomeStyle.section}>
                <div className={`${HomeStyle.sectionContent} ${HomeStyle.baseSection}`}>
                    <div className={HomeStyle.row}>
                        <div className={HomeStyle.col}>
                            <h1>Unprecedented Technological Innovations</h1>
                            <h2>Behind WorldWarDAO hides very unique inovations</h2>
                            <p>
                            Our team is developing unique tools for GameFi that we will be happy to share with the GameFi ecosystem such as our <b>Notification tool</b> through our <b>Telegram Bot</b> or our in-game <b>Milestone system</b> currently in development.
                            </p>
                            <a href="/telegrambot">Learn more about our Telegram Bot</a>
                        </div>
                        <div className={HomeStyle.col}>
                            <div className={HomeStyle.imageOnMac}>
                                <img src={AppOnMac} />
                                <div className={HomeStyle.glow}></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className={HomeStyle.section}>
                <div className={`${HomeStyle.sectionContent} ${HomeStyle.becomeAFarmer}`}>
                    <h1>Become a Soldier</h1>
                    <h2>Join our community of passionate gamers & leaders</h2>
                    <div className={HomeStyle.communityRow}>
                        <div className={HomeStyle.communityCard}>
                            <h2>Get <b>WWD</b> token</h2>
                            <p><b>WWD</b> is your way to become a <b>Share Holder</b> of <b><span style={{color: "#858561"}}>World</span><span style={{color: "#CD2424"}}>War</span><span style={{color: "#FFCC47"}}>DAO</span></b> and earn from the protocol revenue.</p>
                            <button className={HomeStyle.button}>Coming soon</button>
                        </div>
                        <div className={HomeStyle.communityCard}>
                            <h2>Stake on our <b>Vault</b></h2>
                            <p>Stake <b>WWD</b> Token and <b>share protocol revenue</b>. Earn from the <b>in-game fees</b> without being exposed to inflation.</p>
                            <button className={HomeStyle.button}>Coming soon</button>
                        </div>
                        <div className={HomeStyle.communityCard}>
                            <h2>Join the community</h2>
                            <p>Become a member of <br/><b><span style={{color: "#858561"}}>World</span><span style={{color: "#CD2424"}}>War</span><span style={{color: "#FFCC47"}}>DAO</span>.army</b> <br/>community.</p>

                            <div className={HomeStyle.absoluteSVG}>
                                <CommunitySVG/>
                            </div>

                            <div className={HomeStyle.row}>
                                <button className={HomeStyle.communityButton} onClick={()=>window.open("https://twitter.com/WorldWarDAO")}>
                                <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24.6286 5.14305C23.7589 5.52847 22.8245 5.78889 21.8422 5.90659C22.8557 5.30013 23.614 4.34562 23.9755 3.22118C23.0233 3.78679 21.9811 4.18492 20.8943 4.39826C20.1634 3.61791 19.1954 3.10068 18.1404 2.92688C17.0855 2.75308 16.0027 2.93242 15.0601 3.43707C14.1176 3.94171 13.368 4.74343 12.9278 5.71774C12.4875 6.69206 12.3813 7.78446 12.6255 8.82534C10.696 8.72847 8.80847 8.22696 7.08534 7.35337C5.36221 6.47978 3.84202 5.25364 2.62344 3.75451C2.20677 4.47326 1.96719 5.30659 1.96719 6.19409C1.96672 6.99305 2.16347 7.77976 2.53998 8.48444C2.91648 9.18912 3.46111 9.78997 4.12552 10.2337C3.35498 10.2092 2.60143 10.001 1.9276 9.62639V9.68889C1.92753 10.8094 2.31514 11.8955 3.02467 12.7628C3.7342 13.6302 4.72195 14.2253 5.82031 14.4472C5.1055 14.6407 4.35608 14.6692 3.62865 14.5306C3.93854 15.4947 4.54218 16.3379 5.35507 16.9419C6.16796 17.546 7.14939 17.8807 8.16198 17.8993C6.44306 19.2487 4.32019 19.9806 2.1349 19.9774C1.74779 19.9775 1.36102 19.9549 0.976562 19.9097C3.19477 21.3359 5.77692 22.0929 8.41406 22.0899C17.3411 22.0899 22.2214 14.6962 22.2214 8.28368C22.2214 8.07534 22.2161 7.86493 22.2068 7.65659C23.156 6.97011 23.9754 6.12003 24.6266 5.14618L24.6286 5.14305Z"/></svg>
                                </button>
                                <button className={HomeStyle.communityButton} onClick={()=>window.open("https://t.me/WorldWarDAO")}>
                                    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5007 2.08301C6.75065 2.08301 2.08398 6.74967 2.08398 12.4997C2.08398 18.2497 6.75065 22.9163 12.5007 22.9163C18.2507 22.9163 22.9173 18.2497 22.9173 12.4997C22.9173 6.74967 18.2507 2.08301 12.5007 2.08301ZM17.334 9.16634C17.1777 10.8122 16.5007 14.8122 16.1569 16.6559C16.0111 17.4372 15.7194 17.6976 15.4486 17.7288C14.8444 17.7809 14.3861 17.333 13.8027 16.9476C12.8861 16.3434 12.3652 15.9684 11.4798 15.3851C10.4486 14.708 11.1152 14.333 11.709 13.7288C11.8652 13.5726 14.5319 11.1455 14.584 10.9268C14.5912 10.8936 14.5903 10.8592 14.5812 10.8266C14.5721 10.7939 14.5552 10.7639 14.5319 10.7393C14.4694 10.6872 14.3861 10.708 14.3132 10.7184C14.2194 10.7393 12.7611 11.708 9.91732 13.6247C9.50065 13.9059 9.12565 14.0518 8.79232 14.0413C8.41732 14.0309 7.70898 13.833 7.17773 13.6559C6.52148 13.4476 6.01107 13.333 6.05273 12.9684C6.07357 12.7809 6.33398 12.5934 6.82357 12.3955C9.86523 11.0726 11.8861 10.1976 12.8965 9.78092C15.7923 8.57259 16.3861 8.36426 16.7819 8.36426C16.8652 8.36426 17.0632 8.38509 17.1882 8.48926C17.2923 8.57259 17.3236 8.68717 17.334 8.77051C17.3236 8.83301 17.3444 9.02051 17.334 9.16634Z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}