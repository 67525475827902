import { useEffect, useRef } from "react";

import Style from "./RevenueSharing.module.scss";

export default function RevenueSharing({setLocation}){
    const textRef = useRef();

    const parallax = (e) =>{
        let _w = window.innerWidth/2;
        let _h = window.innerHeight/2;
        const speed = (50 - (e.clientX - _w))*0.01;
        const speed2 = (50 - (e.clientY - _h))*0.01;
        textRef.current.style.transform = `translateY(${-speed*speed2}px) rotate(-90deg)`;
    }

    useEffect(()=>{
        setLocation((window.location.pathname).replace("/",""));
        window.addEventListener("mousemove", (e)=>parallax(e))
    },[])

    return (
        <div className={Style.pvp}>
            <span ref={textRef} className={Style.horizontalText}>worldwardao.army</span>
            
            <div className={Style.content}>
                   
                <div className={Style.animatedTitle}>
                    <h1>Revenue Sharing</h1>
                </div>
                <p>
                    WorldWarDAO is about <b>Revenue Sharing</b>! <b>80% of the revenues</b> generated by the game will be <b>distributed to $WWD stakers</b>.
                </p>
                    
                <div className={Style.leftRightSection}>
                    <div className={Style.leftCol}>
                        <h3>Revenue Streams</h3>
                        <p>
                            All the stakers receives only sustainable <b>Rewards</b> and <b>Real Yield</b> from the different <b>Revenue Streams</b> of the game.

                            <ul>
                                <li>Bonuses of Production & Army Power sales in <b>ETH</b></li>
                                <li>PvP Shield sales in <b>ETH</b></li>
                                <li>Country Boosts in <b>ETH</b></li>
                                <li>Payable attacks in <b>ETH</b></li>
                                <li>Selling of GUNZ in <b>ETH</b></li>
                            </ul>

                            You can check the revenue share in our documentation <a target="_blank" href="https://docs.worldwardao.army/">docs.worldwardao.army</a>
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}