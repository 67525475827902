export default function CommunitySVG(){

    return(
        <svg width="116" height="238" viewBox="0 0 116 238" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_0_1)">
            <path d="M29.2826 114.863C39.7001 113.603 47.1237 104.136 45.8638 93.7189C44.6039 83.3014 35.1375 75.8777 24.72 77.1377C14.3025 78.3976 6.87879 87.864 8.13872 98.2815C9.39864 108.699 18.8651 116.123 29.2826 114.863Z" stroke="#FFF384" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
            </g>
            <path d="M24.3516 107.401L32.2937 106.441" stroke="#FFF384" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.8786 87.1475L20.6415 90.946L20.9473 91.6051L21.6686 91.5179L25.8261 91.015L22.7581 93.8655L22.2259 94.3601L22.5318 95.0191L24.2946 98.8177L20.6357 96.7807L20.0009 96.4274L19.4686 96.9219L16.4008 99.7724L17.2072 95.6631L17.3472 94.9501L16.7124 94.5967L13.0534 92.5598L17.2109 92.057L17.9321 91.9698L18.072 91.2568L18.8786 87.1475Z" stroke="#FFF384" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M33.7711 85.3468L35.5341 89.1453L35.8399 89.8043L36.5611 89.7171L40.7187 89.2143L37.6507 92.0647L37.1184 92.5593L37.4244 93.2184L39.1872 97.0169L35.5283 94.98L34.8935 94.6266L34.3612 95.1211L31.2933 97.9716L32.0998 93.8623L32.2398 93.1494L31.605 92.7959L27.9459 90.759L32.1034 90.2562L32.8247 90.169L32.9646 89.456L33.7711 85.3468Z" stroke="#FFF384" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <g filter="url(#filter1_d_0_1)">
            <path d="M111 157C121.493 157 130 148.493 130 138C130 127.507 121.493 119 111 119C100.507 119 92 127.507 92 138C92 148.493 100.507 157 111 157Z" stroke="#99EA9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
            </g>
            <path d="M104 139C106.209 139 108 137.209 108 135C108 132.791 106.209 131 104 131C101.791 131 100 132.791 100 135C100 137.209 101.791 139 104 139Z" stroke="#99EA9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M119 139C121.209 139 123 137.209 123 135C123 132.791 121.209 131 119 131C116.791 131 115 132.791 115 135C115 137.209 116.791 139 119 139Z" stroke="#99EA9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M104 136C104.552 136 105 135.552 105 135C105 134.448 104.552 134 104 134C103.448 134 103 134.448 103 135C103 135.552 103.448 136 104 136Z" fill="#99EA9C"/>
            <g filter="url(#filter2_d_0_1)">
            <path d="M66.4143 229.471C76.8383 230.676 86.2652 223.202 87.4699 212.778C88.6745 202.354 81.2008 192.927 70.7767 191.722C60.3527 190.518 50.9258 197.991 49.7211 208.415C48.5164 218.839 55.9902 228.266 66.4143 229.471Z" stroke="#AC7DDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
            </g>
            <path d="M57.5532 210.327C59.7477 210.581 61.7323 209.007 61.9859 206.813C62.2396 204.618 60.6661 202.634 58.4716 202.38C56.2771 202.126 54.2925 203.7 54.0388 205.894C53.7852 208.089 55.3587 210.074 57.5532 210.327Z" stroke="#AC7DDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M80.4008 212.968C82.5954 213.221 84.58 211.648 84.8336 209.453C85.0872 207.259 83.5138 205.274 81.3193 205.021C79.1247 204.767 77.1401 206.341 76.8865 208.535C76.6329 210.73 78.2063 212.714 80.4008 212.968Z" stroke="#AC7DDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M56.9044 207.232C57.453 207.296 57.9492 206.902 58.0126 206.354C58.076 205.805 57.6826 205.309 57.134 205.246C56.5854 205.182 56.0892 205.576 56.0258 206.124C55.9624 206.673 56.3558 207.169 56.9044 207.232Z" fill="#AC7DDB"/>
            <path d="M81.7403 210.103C82.289 210.166 82.7851 209.773 82.8485 209.224C82.9119 208.675 82.5186 208.179 81.97 208.116C81.4213 208.052 80.9252 208.446 80.8618 208.994C80.7984 209.543 81.1917 210.039 81.7403 210.103Z" fill="#AC7DDB"/>
            <path d="M63.3594 221.064L71.3065 221.983" stroke="#AC7DDB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <g filter="url(#filter3_d_0_1)">
            <path d="M72.5138 46.8036C82.8126 48.8151 92.7921 42.0969 94.8036 31.7981C96.8151 21.4993 90.0969 11.5198 79.798 9.50834C69.4992 7.49685 59.5198 14.215 57.5083 24.5139C55.4968 34.8127 62.215 44.7921 72.5138 46.8036Z" stroke="#9CDDE3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
            </g>
            <path d="M81.0235 24.1029L73.1719 22.5693" stroke="#9CDDE3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M90.4474 22.7958L80.6328 20.8789L78.7159 30.6935L88.5305 32.6104L90.4474 22.7958Z" fill="#9CDDE3"/>
            <path d="M75.7267 19.9208L65.9121 18.0039L63.9952 27.8185L73.8098 29.7354L75.7267 19.9208Z" fill="#9CDDE3"/>
            <path d="M75.1036 41.6468C80.9396 42.8223 82.2134 42.2047 82.9583 40.129" stroke="#9CDDE3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <filter id="filter0_d_0_1" x="3" y="71.998" width="48.0039" height="48.0039" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.952941 0 0 0 0 0.517647 0 0 0 0.65 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            <filter id="filter1_d_0_1" x="87" y="114" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.6 0 0 0 0 0.917647 0 0 0 0 0.611765 0 0 0 0.65 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            <filter id="filter2_d_0_1" x="44.5938" y="186.595" width="48.0039" height="48.0039" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.67451 0 0 0 0 0.490196 0 0 0 0 0.858824 0 0 0 0.65 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            <filter id="filter3_d_0_1" x="52.1523" y="4.15234" width="48.0078" height="48.0078" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.611765 0 0 0 0 0.866667 0 0 0 0 0.890196 0 0 0 0.65 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            </defs>
        </svg>
    )
}