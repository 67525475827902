import SocialRowStyle from "./SocialRow.module.scss";

export default function SocialRow(){

    return(
        <>
            <div className={SocialRowStyle.socialRow}>
                <a href="https://twitter.com/WorldWarDAO" target="_blank"><svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.6286 5.14403C23.7589 5.52945 22.8245 5.78986 21.8422 5.90757C22.8557 5.3011 23.614 4.3466 23.9755 3.22215C23.0233 3.78777 21.9811 4.1859 20.8943 4.39924C20.1634 3.61889 19.1954 3.10166 18.1404 2.92786C17.0855 2.75405 16.0027 2.9334 15.0601 3.43804C14.1176 3.94269 13.368 4.74441 12.9278 5.71872C12.4875 6.69304 12.3813 7.78544 12.6255 8.82632C10.696 8.72944 8.80847 8.22793 7.08534 7.35435C5.36221 6.48076 3.84202 5.25461 2.62344 3.75549C2.20677 4.47424 1.96719 5.30757 1.96719 6.19507C1.96672 6.99402 2.16347 7.78074 2.53998 8.48542C2.91648 9.19009 3.46111 9.79094 4.12552 10.2347C3.35498 10.2101 2.60143 10.0019 1.9276 9.62736V9.68986C1.92753 10.8104 2.31514 11.8965 3.02467 12.7638C3.7342 13.6311 4.72195 14.2262 5.82031 14.4482C5.1055 14.6416 4.35608 14.6701 3.62865 14.5315C3.93854 15.4957 4.54218 16.3388 5.35507 16.9429C6.16796 17.547 7.14939 17.8817 8.16198 17.9003C6.44306 19.2497 4.32019 19.9816 2.1349 19.9784C1.74779 19.9785 1.36102 19.9559 0.976562 19.9107C3.19477 21.3369 5.77692 22.0938 8.41406 22.0909C17.3411 22.0909 22.2214 14.6972 22.2214 8.28465C22.2214 8.07632 22.2161 7.8659 22.2068 7.65757C23.156 6.97109 23.9754 6.121 24.6266 5.14715L24.6286 5.14403V5.14403Z"/></svg></a>
                <a href="https://t.me/WorldWarDAO" target="_blank"><svg  viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5007 2.08301C6.75065 2.08301 2.08398 6.74967 2.08398 12.4997C2.08398 18.2497 6.75065 22.9163 12.5007 22.9163C18.2507 22.9163 22.9173 18.2497 22.9173 12.4997C22.9173 6.74967 18.2507 2.08301 12.5007 2.08301ZM17.334 9.16634C17.1777 10.8122 16.5007 14.8122 16.1569 16.6559C16.0111 17.4372 15.7194 17.6976 15.4486 17.7288C14.8444 17.7809 14.3861 17.333 13.8027 16.9476C12.8861 16.3434 12.3652 15.9684 11.4798 15.3851C10.4486 14.708 11.1152 14.333 11.709 13.7288C11.8652 13.5726 14.5319 11.1455 14.584 10.9268C14.5912 10.8936 14.5903 10.8592 14.5812 10.8266C14.5721 10.7939 14.5552 10.7639 14.5319 10.7393C14.4694 10.6872 14.3861 10.708 14.3132 10.7184C14.2194 10.7393 12.7611 11.708 9.91732 13.6247C9.50065 13.9059 9.12565 14.0518 8.79232 14.0413C8.41732 14.0309 7.70898 13.833 7.17773 13.6559C6.52148 13.4476 6.01107 13.333 6.05273 12.9684C6.07357 12.7809 6.33398 12.5934 6.82357 12.3955C9.86523 11.0726 11.8861 10.1976 12.8965 9.78092C15.7923 8.57259 16.3861 8.36426 16.7819 8.36426C16.8652 8.36426 17.0632 8.38509 17.1882 8.48926C17.2923 8.57259 17.3236 8.68717 17.334 8.77051C17.3236 8.83301 17.3444 9.02051 17.334 9.16634Z" /></svg></a>
                <a href="https://docs.worldwardao.army" target="_blank"><svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.9288 6.42741V18.8128C22.9288 19.8232 22.1059 20.7503 21.0955 20.8753L20.7726 20.917C19.0643 21.1462 16.658 21.8545 14.7205 22.667C14.0434 22.9482 13.2934 22.4378 13.2934 21.6982V7.20866C13.2934 6.82324 13.5122 6.46908 13.8559 6.28158C15.7622 5.25033 18.6476 4.33366 20.6059 4.16699H20.6684C21.9184 4.16699 22.9288 5.17741 22.9288 6.42741ZM11.1663 6.28158C9.26009 5.25033 6.37467 4.33366 4.41634 4.16699H4.34342C3.09342 4.16699 2.08301 5.17741 2.08301 6.42741V18.8128C2.08301 19.8232 2.90592 20.7503 3.91634 20.8753L4.23926 20.917C5.94759 21.1462 8.35384 21.8545 10.2913 22.667C10.9684 22.9482 11.7184 22.4378 11.7184 21.6982V7.20866C11.7198 7.01845 11.6691 6.83149 11.5718 6.66806C11.4745 6.50463 11.3342 6.37097 11.1663 6.28158ZM5.21842 9.43782H7.56217C7.76937 9.43782 7.96809 9.52014 8.1146 9.66665C8.26111 9.81316 8.34342 10.0119 8.34342 10.2191C8.34342 10.4263 8.26111 10.625 8.1146 10.7715C7.96809 10.918 7.76937 11.0003 7.56217 11.0003H5.21842C5.01122 11.0003 4.81251 10.918 4.666 10.7715C4.51948 10.625 4.43717 10.4263 4.43717 10.2191C4.43717 10.0119 4.51948 9.81316 4.666 9.66665C4.81251 9.52014 5.01122 9.43782 5.21842 9.43782V9.43782ZM8.34342 14.1253H5.21842C5.01122 14.1253 4.81251 14.043 4.666 13.8965C4.51948 13.75 4.43717 13.5513 4.43717 13.3441C4.43717 13.1369 4.51948 12.9382 4.666 12.7916C4.81251 12.6451 5.01122 12.5628 5.21842 12.5628H8.34342C8.55062 12.5628 8.74934 12.6451 8.89585 12.7916C9.04236 12.9382 9.12467 13.1369 9.12467 13.3441C9.12467 13.5513 9.04236 13.75 8.89585 13.8965C8.74934 14.043 8.55062 14.1253 8.34342 14.1253V14.1253Z"/></svg></a>
            </div>        
        </>
    )
}